// Numeros

.detalhesVeiculo{
    padding: 0px 0 120px;
    .row{
        > div{}
    }
    .dados{
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 4px 25.3px 0px #efefef9e;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: -30px;
        position: relative;
        z-index: 2;
        margin-bottom: 40px;
    }
    h2{
        color: #000;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 0.56px;
        text-transform: uppercase;
    }
    h4{
        color: #A1A1A1;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        margin-bottom: 0;
        position: relative;
        width: 150px;
        height: 85px;
        top: 15px;
        left: 4px;
        float: left;
        border-radius: 6px;
        display: inline-block;
        img{
            width: 100%;
        }
    }
    h5{
        color: #969696;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        text-transform: uppercase;
        margin-bottom: 2px;
        margin-top: 45px;
    }
    h3{
        color: #000;
        font-size: 29px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        text-transform: uppercase;
        padding: 0 20px 0 0px;
        float: left;
        width: calc(100% - 150px);
        text-align: left;
        span{
            color: #828282;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 19px;
            padding: 0;
            display: inline-block;
            width: 100%;
            text-transform: initial;
            margin-top: 10px;
        }
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        margin-top: 50px;
        width: 90%;
        li{
            list-style: none;
            padding: 0;
            margin: 0;
            border-bottom: 1px solid #DEDEDE;
            color: #272727;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 0.72px;
            text-transform: uppercase;
            padding-bottom: 12px;
            strong{
                color: var(--tertiaryColor);
                font-size: 14px;
                position: relative;
                top: -2px;
                margin-left: 6px;
            }
            span{
                color: #A7A7A7;
                font-size: 12px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 0.6px;
                text-transform: uppercase;  
                display: inline-block;
                width: 100%;
            }
            &:last-child{
                border: none;
            }
        }
    }
    .valor{
        width: 100%;
        justify-content: space-between;   
        background: #000 url(./img/bgPreco.png) no-repeat bottom right;
        padding: 40px 60px;
        padding-bottom: 50px;
        border-radius: 12px;
        span{
            color: #949494;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px;
            text-transform: uppercase;
            margin-bottom: 0;
            padding-bottom: 0;
            width: 100%;
            display: inline-block;
        }
        h3{
            color: #FFF;
            font-size: 29px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px; 
            text-transform: uppercase;
            margin-top: 0;
            padding-top: 0;
            margin-bottom: 20px;
            padding-left: 0;
            width: 100%;
        }
        .btn{
            &.btn-primary{
                background: none;
                line-height: 18px;
                border: 1px var(--whiteColor) solid !important;
                width: 100%;
                margin-right: 16px;
                text-transform: uppercase;
                font-size: 16px !important;
                padding: 10px 0 !important;
                svg{
                    transform: rotate(45deg);
                    font-size: 24px;
                    color: #B8A558;
                }
            }
            &.btn-icon{
                border: 1px solid #A7A7A7 !important;
                color: #A7A7A7;
                background: none;
                line-height: 18px;
                width: 80px;
                font-size: 30px !important;
                
            }
            &:hover{
                background: var(--tertiaryColor);
                color: var(--whiteColor);
                border-color: var(--tertiaryColor) !important;
            }

        }
    }
    .imgDetalhe{
        width: 100%;
        height: 590px;
        float: right;
        margin-top: 40px;
        position: relative;
        border-radius: 5px;
        background-color:  #FFFFFF;
        box-shadow: 0px 4.48px 28.224px 0px #AEAEAE;
        &::before{
            content: "";
            width: 100%;
            height: 60%;
            background: #DEDEDE;
            position: absolute;
            top: calc(50% - 30px);
            left: -40px;
            z-index: -1;
        }
        &::after{
            content: "";
            width: 100%;
            height: 60%;
            border: 1px solid #DEDEDE;
            position: absolute;
            top: -40px;
            right: -40px;
            z-index: -1;
        }
        span{
            border-radius: 5.542px;
            border: 1.385px solid #2CBC46;
            background: linear-gradient(90deg, rgba(48, 189, 68, 0.55) -17.83%, rgba(29, 29, 29, 0.90) 130.07%);
            color: var(--whiteColor);
            font-weight: 600;
            top: 30px;
            right: 30px;
            border-radius: 4px;
            position: absolute;
            padding: 6px 40px;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 1.2px;
        }

    }
    .playVideos{
        border-radius: 12px;
        border: 2px solid #9e9c9c;
        background: rgba(35, 35, 35, 1);
        width: 100%;
        height: 320px;
        display: inline-block;
        opacity: 0.9;
        position: relative;
        &:hover{
            cursor: pointer;
            opacity: 1;
        }
        p{
            color: #FFF;
            font-size: 12px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: 0.72px;
            text-transform: uppercase;
            width: 100%;
            top: 190px;
            float: right;
            line-height: 64px;
            text-align: center;
            position: absolute;
            z-index: 10;
            padding-left: 0;
        
        }
        .play{
            background: #FFF;
            width: 100%;
            float: left;
            height: 100%;
            border-radius: 5px 0 0 5px;
            position: relative;
            border-radius: 12px;
            &:before{
                content: "";
                background: rgba(237, 237, 237, 0.39) url(../../Home/Video/img/play.png) center no-repeat;
                border: solid  1px rgba(237, 237, 237, 0.39);
                backdrop-filter: blur(2px);
                position: absolute;
                top: 50%;
                width: 88px;
                height: 88px;
                z-index: 1;
                left:50%;
                margin-left: -44px;
                margin-top: -50px;
                border-radius: 200px;
            }
            i{
                font-size: 80px;
                color: var(--whiteColor);
                position: absolute;
                left: 50%;
                top: 50%;
                z-index: 2;
                margin-left: -20px;
                margin-top: -32px;
                opacity: 0.7;
            }
        }
        &::before{
            content: "";
            background: rgba(35, 35, 35, 0.5);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            border-radius: 12px;
        }
        
        
    }
    .videosSom{
        padding-bottom: 40px;
    }
    .playSom{
        border: 2px solid #9e9c9c;
        background: rgba(35, 35, 35, 1);
        width: 100%;
        height: 320px;
        display: inline-block;
        position: relative;
        overflow: hidden;
        border-radius: 12px;
        &:hover{
            cursor: pointer;
            opacity: 1;
        }
        .play{
            width: 100%;
            float: left;
            height: 100%;
            border-radius: 5px 0 0 5px;
            position: relative;
            border-radius: 12px;
            .icons {
                color: #fff;
                font-size: 24px;
            }
            &:before{
                content: "";
                background: rgba(237, 237, 237, 0.39) url(./img/icon-som.png) center no-repeat;
                border: solid  1px rgba(237, 237, 237, 0.39);
                backdrop-filter: blur(2px);
                position: absolute;
                top: 50%;
                width: 88px;
                height: 88px;
                z-index: 1;
                left:50%;
                margin-left: -44px;
                margin-top: -50px;
                border-radius: 200px;
                border-radius: 12px;
            }
            i{
                font-size: 80px;
                color: var(--whiteColor);
                position: absolute;
                left: 50%;
                top: 50%;
                z-index: 2;
                margin-left: -20px;
                margin-top: -32px;
                opacity: 0.7;
            }
        }
        .play.playing::before, .play.playing::after {
            animation: pulse 1.5s infinite;
        }
        @keyframes pulse {
            0% {
                transform: scale(1);
                opacity: 1;
            }
            100% {
                transform: scale(1.2);
                opacity: 0;
            }
        }
        h3{
            background: #373737;
            color: #fff;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: .36px;
            line-height: 42px;
            padding-bottom: 20px;
            padding-left: 0;
            position: absolute;
            text-align: center;
            text-transform: uppercase;
            top: 0;
            width: 100%;
            padding-top: 17px;
            z-index: 1;
        }
        &::before{
            content: "";
            background: rgba(35, 35, 35, 0.5);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            border-radius: 12px;
        }
    }
    span.Vendido{
        border-radius: 6px;
        border: 0.845px #2CBC46;
        background: rgba(48, 189, 68, 0.83); 
        color: #257530;
        font-size: 12px;
        font-style: normal;
        font-weight: 900;
        letter-spacing: 0.71px;
        line-height: 24px;
        text-transform: uppercase;
        padding: 8px 20px;
        display: inline-block;
        margin: 14px 0;
        .icons{
            font-size: 20px;
            position: relative;
            top: -2px;
        }
    }
}


@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .detalhesVeiculo {
        padding: 0px 0 40px;
        .container{
            .row{
                > div{
                    padding: 20px 20px;
                    margin-top: -30px;
                }
            }
        }
        .imgDetalhe{
            box-shadow: none;
            border-radius: 0;
            margin-top: 0;
            height: 300px;
            &::after, &::before{
                display: none;
            }
        }
        h4 {
            width: 90px;
            height: 85px;
            top: 15px;
            left: 0;
        }
        ul {
            margin: 0;
        }
        h3 {
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 10px;
            width: 100%;
            padding: 0;
            margin-top: 10px;
        }

        .valor {
            padding: 15px 20px;
            h3 {
                font-size: 18px;
            }
        }
        .btn.btn-down {
            margin: 30px auto;
        }
        p {
            color: #8F8A8A;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 23px;
            margin-bottom: 20px;
        }
        .acoes {
            .btn.btn-primary {
                font-size: 14px !important;
            }
        }
        .playVideos {
            width: 100% !important;
            bottom: 0 !important;
            right: 0 !important;
            height: 120px;

            .play{
                width: 100% !important;
                &:before{
                    background-size: 10px;
                    top: 50%;
                    width: 36px;
                    height: 36px;
                    z-index: 1;
                    left: 50%;
                    margin-left: -18px;
                    margin-top: -20px;
                    border-radius: 200px;
                }
            }
            p{
                display: none;
            }
        }
        .playSom {
            height: 200px;
            h3 {
                font-size: 18px;
                line-height: 48px;
                top: -11px;
                padding-bottom: 0;
                font-size: 14px;
            }
            .play{
                &:before {
                    margin-top: -20px;
                }
            }
        }
        h2{
            text-align: center;
            margin-top: 60px;
            text-align: left !important;
            font-size: 24px;
            line-height: 30px;
        }
        .dados{
            margin-bottom: 54px;
            margin-top: -22px;
        }
    }
    ul {
        li{
            width: 50%;
            display: inline-block;
            border-bottom: none !important;
            strong {
                display: block;
                margin-left: 0 !important;
                margin-top: 4px;
            }
         }
    }
}


