// Page404

.Importacao{
    text-align: center;
    padding: 180px 0 80px;
    min-height: 700px;
    background: #F5F5F5;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    h3{
        color: var(--primaryColor);
        font-size: 38px;
        font-style: normal;
        font-weight: 800;
        line-height: 112.3%;
        letter-spacing: 3px;
        text-transform: uppercase;
        margin-top: 20px;
    }
    h4 {
        color: var(--primaryColor);
        font-weight: 900;
        text-transform: uppercase;
        font-size: 42px;
        line-height: 40px;
    }
    p{
        color: #2F2F2F;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height:  27px;
        letter-spacing: 1.08px;
    }
    a{
        border-radius: 39px;
        border: 1px solid var(--primaryColor) !important;
        color: var(--primaryColor) !important;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        padding: 6px 10px  6px 20px;
        svg{
            transform: rotate(45deg);
            font-size: 26px;
            color: #B8A558;
        }
    }
}