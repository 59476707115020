// SobreBanner

.SobreBanner{
    position: relative;
    background: url(./img/bgBanner.png) no-repeat center;
    height: 680px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .titilos{
        color: var(--whiteColor) !important;
        text-align: center;
        font-size: 43px;
        font-style: normal;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 0.86px;
        text-transform: uppercase;
        strong{
            color: var(--tertiaryColor);
        }
        i{
            background: url(../../../components/Home/ExperienciaPersonalizada/img/icon-exclusividade.svg) no-repeat center;
            width: 40px;
            height: 30px;
            display: inline-block;
        }
    }
    h2{
        margin-bottom: 0;
        padding-bottom: 20px;
    }
}
.marcasBanner{
    padding: 0;
    position: relative;
    overflow: hidden;
    margin-top: -190px;
    .itemMarcas{
        border-radius: 9.255px;
        background: linear-gradient(180deg, #000 0%, #2F2F2F 100%);
        height: 140px;
        text-align: center;
        img{
            filter: grayscale(100%);
            display: inline-block;
        }
    }
    .titilos {
        padding-left: 15px;
        color: var(--whiteColor);
        text-align: center;
        padding-bottom: 0;
        font-weight: 400;
        font-size: 24px;
    }
    .sliderMarcas{
        padding:  0;
    }
    .slick-slider{
        .slick-slide{
            padding: 10px;
        }
    }
    .slick-arrow{
        display: none !important;
    }
    .slick-dots {
        text-align: right;
        padding-right: 5px;
        li{
            padding: 0;
            margin: 0;
            button{
                background: #CDCDCD;
                border-radius: 60px;
                width: 15px;
                height: 15px;
                margin-left: 3px;
                &::before{
                    display: none;
                }
            }
            &.slick-active{
                button{
                    background: #9B9A9A;
                }
            }
        }
    }
}



@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .SobreBanner{
        padding-left: 20px;
        padding-right: 20px;
        height: 540px;
        &::before{
            display: none;
        }
        .boxSobreBanner {
            min-height: 330px;
            .textSobreBanner {
                padding-bottom: 30px;
                h3 {
                    font-size: 24px;
                }
                h2 {
                    font-size: 25px;
                    line-height: 36px;
                    width: 70%;
                    br{
                        display: none;
                    }
                }
            }
        }
    }
}



