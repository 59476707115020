// Header

header{
    padding: 0;
    margin-bottom: -120px;
    z-index: 10;
    height: 120px;
    position: relative;
    top: 30px;
    .container{
      background: var(--whiteColor);
      border-radius: 6px;
      overflow: hidden;
      box-shadow: 4px -2px 13px -2px rgba(34,34,34,0.36);
      .row{
        > div{
          padding: 0;
        }
      }
      .navbar {
        padding: 0;
        .navbar-brand{
          background: var(--primaryColor) !important;
          width: 180px;
          height: 100px;
          text-align: center;
          line-height: 80px;
          a{
            color: var(--secondaryColor);
          }
          img{
            width: 80px;
          }
        }
      }

    }
    .btn-primary{
      border: 1px solid var(--whiteColor) !important;
    }
    &.light{   
      margin-bottom: 0;
      nav{
        .navbar-nav{
          a{
            color: var(--secondaryColor);
          }
        }
      }
      a.btnBusca {
        border: 1px solid var(--primaryTextColor);
        color: var(--whiteColor) !important;
        &:hover{
          background: var(--primaryTextColor);
          color: var(--whiteColor) !important;
          border-color: var(--primaryTextColor) !important;
          &::before{
            border-color: var(--primaryTextColor) !important;
          }
        }
      }
    }
    &.search{
      .container{
          background: none;
          .navbar-brand{
              background: none !important;
          }
          .btnBusca{
            display: none;
          }
          .justify-content-center {
            justify-content: right !important;
            a {
              color: var(--whiteColor);
            }
          }
      }
    }
    nav{
      background: none !important;
      .logo{
        width: 130px;
        position: relative;
        top: 0px;
      }
      .navbar-nav{
        li{
          position: relative;
          &:hover, &.active{
            color: var(--primaryColor);
            &:before{
              content: "";
              background: url(../../../src/components/Home/Destaques/img/IconDestaque.svg) no-repeat center / cover;
              width: 20px;
              height: 16px;
              display: inline-block;
              position: absolute;
              top: -21px;
              left: 60%;
              margin-left: -18px;
            }
            a{
              color: var(--primaryColor);
            }
          }
        }
        a{
          color: var(--secondaryColor);
          text-decoration: none;
          padding: 0 14px;
          font-size: 14px;
          text-transform: uppercase;
          position: relative;
          font-weight: 600;
        }
        .icons {
          color: var(--tertiaryColor);
          font-size: 20px;
        }
      }
    }
    .btnsMobile{
      display: none;
    }
    .listVeiculos{
      display: none;
    }
    a.btnBusca {
      border: 1px solid var(--whiteColor);
      border-radius: 0;
      padding: 8px 10px !important;
      color: var(--whiteColor) !important;
      margin-right: 20px;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 14px;
      line-height: 45px;
      position: relative;
      transition: all ease-in-out 0.2s;
      margin-left: 20px;
      background: var(--primaryTextColor);
      width: 65px;
      height: 65px;
      display: inline-block;
      text-align: center;
      svg{
        position: relative;
        margin-left: 0;
        font-size: 24px;
        
      }
      &:hover{
        background: var(--tertiaryColor);
        color: var(--whiteColor) !important;
        border-color: var(--tertiaryColor) !important;
        &::before{
          border-color: var(--tertiaryColor) !important;
        }
      }
    }
}

  @media(min-width: 1621px) and (max-width: 1800px) {
    
  }
  @media(min-width: 1024px) and (max-width: 1620px) {
   
  
  }
  @media(min-width: 1024px) and (max-width: 1280px) {
  
  }
  @media (max-width: 768px) {
    header{
      padding: 20px;
      height: 100px;
      top: 0;
      .container {
        border-radius: 0px;
        .navbar {
          .navbar-brand {
            width: 100px;
            height: 60px;
            line-height: 50px;
            img {
              width: 40px;
            }
          }
        }
      }
      .btnsMobile{
        display: block;
        margin-right: 20px;
        a{
          color: var(--primaryColor);
          border: none;
          background: none;
          font-size: 26px;
          position: relative;
          top: -4px;
          margin-left: 20px;
        }
      }
      .btnFalecConosco{
        display: none;
      }
      .navbar-brand{
        img{
          width: 100px;
        }
      }
      &.light{
        background: none;
        .btnsMobile{
          a{
            color: #000;
          }
        }
      }
      .listVeiculos{
        display: inline-block;
        padding: 20px 20px 20px;
        width: 100%;
        list-style: none;
        h3{
          color: rgba(47, 47, 47, 0.33);
          font-size: 22px;
          font-style: normal;
          font-weight: 900;
          line-height: normal;
          text-transform: uppercase;
          position: absolute;
          left: 40px;
        }
        .itemNossosVeiculos{
            max-width: 320px;
            margin-bottom: 40px;
            margin: 0 auto 5px;
            position: relative;
            .foto{
                width: 160px;
                height: 60px;
                display: inline-block;
                text-align: center;
                float: right;
                margin-bottom: -10px;
                img{
                    position: relative;
                    z-index: 1;
                    width: 95%;
                }
            }
            .desc{
                border-radius: 3.737px;
                border: 1px solid #D7D7D7;
                background: #EEE;
                margin-top: -80px;
                display: inline-block;
                width: 100%;
                padding: 20px 10px 10px 20px;
                position: relative;
                display: flex;
                justify-content: space-between;
                transition: all ease-in 0.2s;
                .servico{
                    .textServico{
                        h6{
                            color: var(--secondaryColor);
                            text-transform: uppercase;
                            font-size: 12px;
                            transition: all ease-in 0.2s;
                            text-align: left;
                            margin-top: 10px;
                            margin-bottom: 2px;
                        }
                        h5{
                            color: var(--primaryTextColor);
                            font-size: 16px;
                            text-transform: uppercase;
                            font-weight: bold;
                            transition: all ease-in 0.2s;
                            text-align: left;
                        }
                        
                    }
                }
                .btn-plus {
                    width: 38px;
                    height: 38px;
                    font-size: 15px;
                    line-height: 36px;
                    margin-top: 10px;
                    transition: all ease-in 0.2s;
                    background: var(--primaryTextColor);
                    border: none !important;
                    color: var(--whiteColor);
                }
            }
        }
      }
      .collapse {
        width: 100%;
        height: 100%;
        background: #FFF;
        position: fixed;
        top: 0;
        left: 0;
        padding: 0;
        text-align: center;
        &::before {
          content: "Menu";
          text-transform: uppercase;
          background: linear-gradient(90deg, rgba(217, 217, 217, 0.6) 0%, rgba(217, 217, 217, 0.3) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 51px;
          position: absolute;
          right: -22px;
          top: 119px;
          font-weight: 900;
          transform: rotate(270deg);
          opacity: 0.4;
        }
        .navbar-nav {
          padding: 65px 60px 20px;
          background: var(--primaryTextColor) url(./img/bgMenuMobile.png) no-repeat center -1px / cover;
          height: 250px;
          width: 100%;
          text-align: left;
          li{
            padding-bottom: 10px;
            a {
              color: #FFF;
              font-size: 16px;
            }
          }
        }
        a.closeMenu {
          position: absolute;
          top: 27px;
          right: 32px;
          color: #FFF;
          font-size: 26px;
      }
      }
      &.search{
        .container{
            background: #FFF;
            .navbar-brand{
                background: var(--primaryColor) !important;
            }
        }
      }
    } 

  }
  @media(min-width: 1621px) and (max-width: 1800px) {
    
  }
  @media(min-width: 1024px) and (max-width: 1620px) {
   
  
  }
  @media(min-width: 1024px) and (max-width: 1380px) {
    header{
      height: 100px !important;
      img.logo {
        width: 100px;
      } 
      nav {
        .navbar-nav {
          a {
            font-size: 13px;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {

  }

  @media (max-width: 380px) {
    header {
      padding: 0 20px 0 20px;
      .collapse {
        a.closeMenu {
          top: 16px;
        }
        .navbar-nav {
          background: var(--primaryTextColor) url(http://localhost:3001/static/media/bgMenuMobile.6ed068c….png) no-repeat center / cover;
          height: 100%;
          padding: 160px 42px 20px;
          li {
            padding-bottom: 6px;
            width: 100%;
            a {
              font-size: 16px;
              
            }
            &.active{
              &:before{
                display: none;
              }
              a{
                color: var(--secondaryColor);
              }
            }
          }
        }
        &::before {
          right: inherit;
          top: 80px;
          font-size: 39px;
          opacity: 0.2;
          left: 55px;
          transform: inherit;
        }
      }
      .listVeiculos {
        padding: 20px 20px 20px;
        .itemNossosVeiculos {
          margin: 0 auto 10px;
          .desc {
            padding: 10px 10px 4px 20px;
          }
        }
        h3 {
          margin-top: 20px;
          font-size: 18px;
          left: 30px;
        }
        .foto {
          img {
            width: 80%;
          }
        }
      }
    }
  }