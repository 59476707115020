// Numeros

.Banner{
    height: 710px;
    position: relative;
    
    z-index: 1;
    margin-top: -120px;
    .container-fluid{
        padding: 0;
        overflow: hidden;
    }
    &::before{
        content: "";
        width: 100%;
        height: 120px;
        position: absolute;
        left: 0;
        bottom: 20px;
        z-index: 0;
        background: linear-gradient(90deg, #000 0%, #333 100%);
    }
    .row{
        position: relative;
        height: 810px;
    }
    .imgBanner {
        height: 810px;
        span{
            background: rgb(48,189,68);
            background: linear-gradient(90deg, rgba(191,191,191,0.3) 55%, rgba(255,255,255,0.7) 90%);
            border: 1px solid #DDDBDB;
            color: #606060;
            font-weight: 600;
            top: 0px;
            left: 50%;
            border-radius: 4px;
            position: absolute;
            width: 200px;
            margin-left: -100px;
            padding: 2px 14px;
            text-transform: uppercase;
            font-size: 14px;
            text-align: center;
        }
        .saiba{
            background: rgba(255, 255, 255, 0.58);
            border: 1px solid #CFCFCF;
            backdrop-filter: blur(2px);
            width: 153px;
            height: 153px;
            position: absolute;
            bottom: 35%;
            right: 25%;
            border-radius: 200px;
            text-align: center;
            transition: all ease-in 0.2s;
            &:hover{
                background: rgba(255, 255, 255, 0.8);
            }
            span{
                position: relative;
                background: none;
                padding: 0;
                border: 0;
                margin: 0 !important;;
                width: 100% !important;
                text-align: center;
                left: 0;
                margin: auto;
               
            }
            i{
                font-size: 30px;
                margin-top: 30px;
                display: block;
                position: relative;
                width: 100%;
            }
        }
        &:hover{
            cursor: pointer !important;
        }
    }
    .slick-prev, .slick-next{
        right: 60px;
        bottom: 40px;
        top: inherit;
        left: inherit;
        z-index: 9;
        width: 50px;
        height: 50px;
        opacity: 0.8;
        &::before{
            display: none;
        }
    }
    .slick-next{
        background: url(../Banner/img/next.png) no-repeat  !important;
        &:hover{
            background: url(../Banner/img/next.png) no-repeat;
            opacity: 1;  
            z-index: 9;
        }
    }
    .slick-prev{
        bottom: 100px;
        background: url(../Banner/img/prev.png) no-repeat !important;
        &:hover{
            background: url(../Banner/img/prev.png) no-repeat;
            z-index: 9;
        }
    }
    .slick-list{
        overflow: inherit;
        .slick-slide{
            position: relative;
            &.slick-active.slick-current{
                .descBanner{
                    opacity: 1;
                }
            }
        }
    }
    .slick-dots{
        display: none !important;
    }
    .controlSlider{
        width: 1320px;
        position: relative;
        margin: auto;
        a.nextSlider {
            display: none;
            border: 1px solid #D0D0D0;
            width: 52px;
            height: 52px;
            z-index: 1;
            background: #FFF;
            position: absolute;
            right: -24px;
            bottom: 6px;
            color: #D0D0D0;
            text-align: center;
            line-height: 48px;
            transition: all ease-in 0.2s;
            font-size: 12px;
            &:hover{
                background: #F5F5F5;
                cursor: pointer;
            }
        }
        a.prevSlider {
            display: none;
            border: 1px solid #D0D0D0;
            width: 52px;
            height: 52px;
            z-index: 1;
            background: #FFF;
            position: absolute;
            right: -24px;
            bottom: -45px;
            color: #D0D0D0;
            text-align: center;
            line-height: 48px;
            transition: all ease-in 0.2s;
            font-size: 12px;
            &:hover{
                background: #F5F5F5;
                cursor: pointer;
            }
        }

    }
    .descBanner{
        width: 1320px;
        height: 120px;
        position: absolute;
        left: 50%;
        margin-left: -660px;
        top: 590px;
        display: flex;
        transition: all ease-in 0.2s;
        opacity: 0;
        .descVideo{
            display: flex;
            justify-content: space-between;
            width: 40%;
            place-content: flex-start;
            .desc{
                padding: 34px 60px;
                h4{
                    color: var(--secondaryColor);
                    text-transform: uppercase;
                    font-size: 13px;
                    margin-bottom: 6px;
                }
                h3{
                    color: var(--whiteColor);
                    text-transform: uppercase;
                    font-size: 20px;
                }
            }
            .video{
                width: 320px;
                background: #CDCDCD;
                height: 160px;
                position: absolute;
                top: -0px;
                z-index: 9999;
                right: 0;
                border-radius: 10px;
                overflow: hidden;
                transition: all ease-in 0.2s;
                &::before{
                    content: "";
                    background: rgba(0,0,0,0.4);
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    transition: all ease-in 0.2s;
                }
                i{
                    background: rgba(237, 237, 237, 0.39) url(../../Home/Video/img/play.png) center no-repeat;
                    border: solid  1px rgba(237, 237, 237, 1);
                    backdrop-filter: blur(2px);
                    position: absolute;
                    top: 50%;
                    width: 68px;
                    height: 68px;
                    z-index: 1;
                    left:50%;
                    margin-left: -34px;
                    margin-top: -34px;
                    border-radius: 200px;
                }
                &:hover{
                    cursor: pointer;
                    &::before{
                        background: rgba(0,0,0,0.6);
                    }
                    background-size: 105% !important;
                    
                }
            }
        }
        .inclusoPreco{
            padding: 16px 24px;
            display: flex;
            width: calc(100% + 1px);
            width: 50%;
            color: var(--whiteColor);
            .incluso{
                width: 50%;
                list-style: none;
                margin-bottom: 0;
                height: 75px;
                li{
                    font-size: 11px;
                    font-weight: bold;
                    line-height: 20px;
                    color: var(--primaryTextColor);
                    border-bottom: 1px solid #EAEAEA;
                    &:last-child{
                        border: none
                    }
                }
            }
            .preco {
                padding-left: 60px;
                span{
                    color: var(--tertiaryColor);
                    font-size: 12px;
                    text-transform: uppercase;
                    margin-top: 16px;
                    display: inline-block;
                    margin-top: 0;
                }
                h3{
                    color: #272727;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }
        .row{
            > div:first-child{
                padding-right: 0;
            }
            > div:last-child{
                padding-left: 0;
            }
        }
    }
    .itemBanner {
        &:hover{
            cursor: pointer;
        }
        &.tipo2{
            margin-top: -100px;
            overflow: hidden;
            .chamadaBanner{
                background: var(--whiteColor);
                width: 50%;
                height: 810px;
                float: left;
                .textoBanner{
                    width: 660px;
                    float: right;
                    min-height: 810px;
                    display: table;
                    position: relative;
                    .alignBanner{
                        display: table-cell;
                        vertical-align: middle;
                        min-height: 810px;
                        position: relative;
                        h3{
                            color: var(--primaryTextColor);
                            font-size: 38px;
                            font-style: normal;
                            width: 80%;
                            font-weight: 400;
                            line-height: 42px;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                        }
                        h2{
                            color: var(--primaryTextColor);
                            font-size: 52px;
                            font-style: normal;
                            width: 80%;
                            font-weight: 900;
                            line-height: 60px;
                            letter-spacing: 3.9px;
                            text-transform: uppercase;
                            margin-bottom: 20px;
                        }
                        h5{
                            background: none;
                            color: var(--tertiaryColor);
                            font-weight: 600;
                            border-radius: 4px;
                            display: inline-block;
                            padding: 6px 0;
                            text-transform: uppercase;
                            font-size: 14px;
                            width: auto;
                            margin-bottom: 0px;
                            margin-top: 80px;
                        }
                        p{
                            color: #FFF;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 30px;
                            letter-spacing: 0.9px;
                        }
                        a{
                            border-radius: 53px;
                            color: var(--primaryTextColor);
                            border: 1px solid #212121; 
                            color: var(--primaryTextColor);
                            padding: 6px 20px 6px 30px;
                            margin-right: 10px;
                            text-decoration: none;
                            display: inline-block;
                            transition: all ease-in 0.2s;
                            svg{
                                transform: rotate(45deg);
                                font-size: 24px;
                                color: #B8A558;
                            }
                            &.link{
                                padding: 6px 30px 6px 30px;
                                border-radius: 53px;
                                background: var(--primaryTextColor);
                                color: var(--whiteColor);
                            }
                            &:hover{
                                background: var(--tertiaryColor);
                                color: var(--whiteColor);
                                border: 1px solid var(--tertiaryColor);
                                svg{
                                    transform: rotate(45deg);
                                    font-size: 24px;
                                    color: var(--primaryTextColor);
                                }
                            }
                        }
                    }

                }
 
            }
            .imgBanner{
                height: 810px;
                width: 50%;
                float: left;
                background: linear-gradient(180deg, #141414 0%, #2F2F2F 100%);
                span{
                    color: rgba(236, 236, 236, 0.29);
                    font-size: 142px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 82px;
                    letter-spacing: 0;
                    text-transform: uppercase;
                    width: 100%;
                    background: none;
                    opacity: 0.7;
                    padding-top: 60px;
                    text-align: left;
                    padding-left: 14%;
                    border: none;
                    -webkit-text-stroke: 1px rgba(180, 180, 180, 0.6);
                    color: transparent;
                    bottom: 130px;
                    position: absolute;
                    top: inherit;
                }
                img{
                    position: absolute;
                    width: 900px;
                    margin-left: -200px;
                    bottom: 100px;
                }
            }
            .descBanner{
                .marca {
                    width: 16%;
                    display: flex;
                    justify-content: center;
                    display: block;
                    width: 100px;
                }
                .descVideo {
                    .desc {
                        padding: 18px 24px;
                        align-content: center;
                    }
                }
                .inclusoPreco{
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    background: #DBDBDB;
                    align-items: center;
                    position: relative;
                    padding-left: 0;
                    &:before{
                        background: #DBDBDB;
                        content: "";
                        left: -100%;
                        top: 0;
                        height: 100%;
                        position: absolute;
                        z-index: 1;
                        width: 100%;
                    }
                    span{
                        color: #7B7B7B;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: normal;
                        letter-spacing: 0.72px;
                        text-transform: uppercase;
                    }
                    .km, .ano, .preco {
                        padding: 0px 40px 0 40px;
                        list-style: none;
                        margin: 0;
                        font-size: 20px;
                        font-weight: bold;
                        border-left: 1px solid #FFF;
                        height: 50px;
                        li{
                            line-height: 16px;
                            color: var(--primaryTextColor);
                        }
                        span{
                            color: #7B7B7B;
                            font-size: 12px;
                            text-transform: uppercase;
                            display: inline-block;
                            width: 100%;
                            margin-bottom: 2px;
                        }
                        h3{
                            color: #272727;
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }
                    .ano{
                        padding-left: 0;
        
                    }
                    .ano{
                        border: none;
                    }
                }
            }
        }
        &.tipo3{
            .itemBannerContent{
                width: 50%;
                height: 600px;
                float: left;
                
                .textoBanner{
                    width: 660px;
                    float: right;
                    min-height: 600px;
                    display: table;
                    position: relative;
                    .alignBanner{
                        display: table-cell;
                        vertical-align: middle;
                        min-height: 600px;
                        position: relative;
                        h4{
                            border-radius: 3.52px;
                            border: 0.88px solid #2CBC46;
                            background: linear-gradient(90deg, rgba(48, 189, 68, 0.55) -17.83%, rgba(29, 29, 29, 0.00) 130.07%);
                            color: #FFF;
                            font-size: 12.32px;
                            font-style: normal;
                            font-weight: 900;
                            line-height: 131.639%;
                            letter-spacing: 0.739px;
                            text-transform: uppercase;
                            padding: 8px 20px;
                            width: max-content;
                        }
                        h3{
                            color: #FFF;
                            font-size: 52px;
                            font-style: normal;
                            font-weight: 900;
                            letter-spacing: 3.9px;
                            text-transform: uppercase;
                            strong{
                                -webkit-text-stroke-width: 1.2506976127624512;
                                -webkit-text-stroke-color: #FFF;
                                font-size: 42px;
                                font-style: normal;
                                font-weight: 900;
                                line-height: normal;
                                letter-spacing: 2.76px;
                                text-transform: uppercase;
                            }
                        }
                        p{
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            letter-spacing: 0.84px;
                            width: 80%;
                        }
                    }

                }
 
            }
        }
    }
}

@media(min-width: 1621px) and (max-width: 1800px) {
}

@media(min-width: 1024px) and (max-width: 1620px) {
}

@media(min-width: 1024px) and (max-width: 1380px) {
    .Banner {
        height: 530px;
        .itemBanner{
            .imgBanner {
                height: 450px;
                background-size: 55% !important;
                background-position: center !important;
                span {
                    width: 170px;
                    margin-left: -85px;
                    padding: 2px 14px;
                    font-size: 12px;
                }
            }
            &.tipo2 {
                .imgBanner {
                    height: 450px;
                    width: 50%;
                    float: left;
                }
                .chamadaBanner {
                    height: 450px;
                    .textoBanner {
                        min-height: 450px;
                        width: 570px;
                        .alignBanner {
                            min-height: 450px;
                            h2 {
                                font-size: 30px;
                                line-height: 38px;
                            }
                            h5 {
                                padding: 6px 18px;
                                font-size: 12px;

                            }
                        }
                    }
                }
                .descBanner {
                    height: 100px;
                    .marca {
                        width: 20%;
                    }
                    .descVideo {
                        .video {
                            width: 180px;
                            height: 97px;
                        }
                        .desc {
                            h3 {
                                font-size: 18px;
                            }
                        }
                    }
                    .inclusoPreco {
                        padding: 14px 24px;
                        .preco, .ano, .km {
                            padding: 0 20px;
                            font-size: 17px;
                        }
                    }
                }
                .imgBanner {
                    background-size: 100% !important;
                }
            }
            &.tipo3 {
                .imgBanner{
                    background-size: inherit !important;
                }
                .itemBannerContent {
                    .textoBanner {
                        min-height: 400px;
                        .alignBanner {
                            padding-left: 90px;
                            h3 {
                                font-size: 28px;
                                width: 60%;
                            }
                            h4 {
                                font-size: 12px;
                                padding: 4px 16px;
                                line-height: 16px;
                            }
                            p {
                                font-size: 12px;
                                width: 70%;
                            }
                        }
                    }
                }
            }
            
        }
        .descBanner {
            height: 100px;
            top: 390px;
            width: 1140px;
            margin-left: -570px;
            .descVideo{
                .desc {
                    padding: 25px 50px;
                }
            }
             .inclusoPreco {
                padding: 10px 24px;
                .incluso {
                    li {
                        font-size: 10px;
                        line-height: 18px;
                    }
                }
            }

        }
        .controlSlider {
            width: 1140px;
            top: -108px;
        }
        .controlSlider {
            a.nextSlider {
                width: 44px;
                height: 44px;
                right: -20px;
                bottom: 57px;
                line-height: 40px;
            }
            a.prevSlider {
                width: 44px;
                height: 44px;
                right: -20px;
                bottom: 15px;
                line-height: 38px;
            }
        }
    }
}

@media (max-width: 768px) {
    .Banner{
        height: 564px;
        .slick-arrow{
            display: none !important;
        }
        .itemBanner{
            height: 680px;
            .imgBanner {
                position: relative;
                .saiba{
                    display: none;
                }
                span{
                    top: 40px;
                    display: block !important;
                    
                }
                
            }
            &.tipo2 {
                
                .chamadaBanner {
                    height: 455px;
                    width: 100%;
                    .textoBanner{
                        height: 390px;
                        min-height: inherit;
                        width: 100%;
                        padding: 50px 20px 0;
                        .alignBanner{
                            a:not(.link){
                                display: none;
                            }
                            .link{
                                padding: 3px 20px 3px 20px !important;
                                font-size: 16px;
                            }
                            h5{
                               display: none;
                            }
                            h3{
                                margin-top: 180px;
                                font-size: 16px;
                                line-height: 13px;
                            }
                        }
                        h2{
                            color: var(--primaryTextColor);
                            font-size: 28px !important;
                            width: 80%;
                            line-height: 28px !important;
                        }
                    }
                }
                .imgBanner {
                    height: 160px;
                    width: 100%;
                    background: none;
                    img {
                        width: 100%;
                        margin-left: 0;
                        margin-top: 0;
                        bottom: 50px;
                        
                    }
                    span {
                        font-size: 47px;
                        line-height: 47px;
                        width: 100%;
                        text-align: left;
                        left: 0;
                        padding-left: 0;
                        margin: 0;
                        top: -7px;
                        text-align: right;
                    }
                }
                .descBanner {
                    bottom: 0;
                    top: inherit;
                    .inclusoPreco {
                        padding: 29px 10px;
                        .preco, .km, .ano {
                            padding: 0 10px;
                            font-size: 16px;
                            li{
                                width: 100%;
                            }
                        }
                    }
                    .marca {
                        width: 100px;
                    }
                    .descVideo {
                        .desc {
                            padding: 18px 15px;
                            h3{
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            &.tipo3 {
                .itemBannerContent {
                    width: 100%;
                    .textoBanner{
                        width: 100%;
                        padding: 30px;
                        text-align: center;
                        min-height: 384px;
                        .alignBanner{
                            text-align: center;
                            h4{
                                font-size: 13px;
                                text-align: center;
                                margin: auto;
                                margin-bottom: 16px;
                            }
                            h3{
                                font-size: 30px;
                            }
                            p{
                                display: none;
                            }
                            a{
                                display: none;
                            }
                        }
                    }
                }
                .imgBanner {
                    height: 385px;
                    width: 100%;
                    
                }
                .descBanner {
                    .inclusoPreco {
                        padding: 29px 20px;
                        display: inline-block;
                        .preco, .km, .ano {
                            padding: 0 10px;
                            font-size: 16px;
                        }
                        .incluso{
                            width: 60%;
                            padding-left: 0;
                        }
                        .btn {
                            padding: 10px 15px !important;
                            height: 37px;
                            margin-top: 20px;
                            float: right;
                            margin-top: -60px;
                        }
                    }
                    .marca {
                        width: 100px;
                    }
                    .descVideo {
                        .desc {
                            padding: 18px 30px;
                            h3{
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            
        }

        .descBanner {
            width: 100%;
            top: 400px;
            left: 0;
            margin-left: 0;
            display: inline-block;
            padding: 0;
            background: none;
            border: none;
            box-shadow: none;
            .descVideo {
                width: 100%;
                height: 70px;
                padding: 0 20px;
                background: var(--primaryTextColor);
                .desc {
                    padding: 15px 20px 15px 40px;
                    h4 {
                        font-size: 11px;
                        margin-bottom: 4px;
                    }
                    h3{
                        font-size: 14px;
                    }
                }
                .video {
                    width: 120px;
                    height: 67px;
                    position: relative;
                    margin-top: 1px;
                    top: -20px;
                    i {
                        background-size: 10px;
                        top: 50%;
                        width: 36px;
                        height: 36px;
                        z-index: 1;
                        left: 50%;
                        margin-left: -18px;
                        margin-top: -20px;
                        border-radius: 200px;
                    }
                }
            }
            .inclusoPreco {
                width: calc(100% + 1px);
                padding: 18px 24px;
                .incluso {
                    margin-left: 0;
                    padding-left: 10px;
                    li {
                        font-size: 10px;
                        line-height: 18px;
                    }
                }
                .preco {
                    padding-left: 45px;
                    h3 {
                        font-size: 18px;
                    }
                }
            }
            .marca {
                img {
                    width: 34px !important;
                }
            }
        }
        .controlSlider {
            display: none;
        }
        .slick-dots {
            display: block !important;
            bottom: 5px;
            li.slick-active button:before {
                font-size: 15px;
                color: var(--tertiaryColor);
            }
            li button:before{
                color: #696868;
            }
        }
        &::before{
            height: 264px;
            bottom: -39px;
        }
        .slick-prev {
            bottom: 102px;
            right: 90px;
        }
        .slick-next{
            right: 30px;
            bottom: 102px;
        }
    }
}

