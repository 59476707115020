// Diferenciais
.especificacoesVeiculo {
    background: #E6E6E6;
    .color{
        min-height: 400px;
        display: table;
        padding: 20px 20px 20px 0;
        margin-top: -140px;
        &.segundo{
            padding: 20px 0 20px 20px; 
        }
        h2{
            color: #000000;
            font-size: 20px;
            font-style: normal;
            font-weight: 800;
            line-height: 26px;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            border-bottom: 1px solid #C6C6C6;
            padding: 10px 0;
            margin-bottom: 20px;
        }
        h4{
            color: #A7A7A7;
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            line-height: 20px;
            letter-spacing: 0.84px;
            text-transform: uppercase;
            border-bottom: 1px solid #45464A;
            padding-bottom: 6px;
        }
        h3{
            color: var(--whiteColor);
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            line-height: 18px;
            letter-spacing: 0.84px;
            text-transform: uppercase;
            margin-top: 20px;
        }
        h5{
            color: #A7A7A7;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.84px;
        }
        p{
            color: #A7A7A7;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            padding-bottom: 6px;
        }
        .boxColor{
            display: table-cell;
            //vertical-align: middle;
            min-height: 400px;
            background: var(--whiteColor);
            padding: 40px 60px;
            border-radius: 12px;
            ul{
                list-style: none;
                margin: 0;
                padding: 0;
                li:nth-child(odd) {
                    background: #e9e9e9;
                }
                li:nth-child(even) {
                    
                    &:before{
                        background: #F1F1F1 url(./img/icon-check.png) center no-repeat;
                    }
                }
                li{
                    border-bottom: 1px solid #F0F0F0;
                    color: #2F2F2F;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 22px;
                    letter-spacing: 0.96px;
                    padding-bottom: 10px;
                    padding: 5px;
                    padding-left: 10px;
                    
                    &:before{
                        width: 22px;
                        height: 22px;
                        display: inline-block;
                        content: "";
                        float: left;
                        background: #FFF url(./img/icon-check.png) center no-repeat;
                    }
                    span{
                        color: #2F2F2F;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 20px;
                        letter-spacing: 0.72px;
                        display: inline-block;
                        width: calc(100% -  60px);
                        margin-left: 10px;
                    }
                    &:last-child{
                        border: none;
                    }
                }
            }
            
        }
    }
    .white{
        padding: 120px 10% 120px 6%;
        background: var(--whiteColor);
        position: relative;
        min-height: 400px;
        display: table;
        h6{
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: var(--secondaryColor);
            font-weight: 900;
            text-transform: uppercase;
            font-size: 38px;
            color: transparent;
            line-height: 40px;
        }
        
        .boxWhite{
            display: table-cell;
            vertical-align: middle;
            min-height: 400px;
            background: var(--whiteColor);
            padding: 40px 60px;
            border-radius: 12px;

            button{
                border-radius: 12px !important; 
                border: 1px solid #C7C7C7 !important; 
                background: #FFF !important; 
                color: #ABABAB;
                font-size: 22px;
                font-style: normal;
                font-weight: 800;
                letter-spacing: 1.32px;
                text-transform: uppercase;
                margin-right: 20px;
                padding: 30px 30px !important; 
                margin-bottom: 20px;
                &.active{
                    background: #262626 !important;
                    color: var(--whiteColor);
                    box-shadow: 0px 4px 15.6px 0px #C9C9C9;
                    i{
                        border: 1px solid #2CBE48;
                        color: #2CBE48;
                    }
                }
                i{
                    border: 1px solid #C7C7C7;
                    border-radius: 100px;
                    height: 30px;
                    width: 30px;
                    display: inline-block;
                    text-align: center;
                    line-height: 26px;
                    color: #9a9a9a;
                    margin-left: 6px;
                }
            }
        }
    }
}

@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {
    .especificacoesVeiculo {
        .color {
            min-height: inherit;
            background: #FFF;
            padding: 40px 0 40px 0 !important;
            background-size: 140px !important;
            margin-top: -100px;
             .boxColor {
                border-radius: 0;
                padding: 40px 30px;
                ul{
                    li{
                        width: 100%;
                    }
                }
            }
            p{
                font-size: 14px;
                line-height: 24px;
            }
        }
        .white {
            padding: 80px 20px 40px 20px !important;
            min-height: initial;
            .boxWhite {
                button {
                    width: calc(50% - 10px);
                    margin-right: 0;
                    &:nth-child(1){
                        margin-right: 20px;
                    }
                    i {
                        margin-left: 0;
                        margin-top: 10px;
                    }
                }
                ul{
                    height: auto;
                }
            }
        }
    }
}

