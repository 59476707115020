// SobreBanner

.Conheca{
    padding: 120px 0;
    position: relative;
    margin-top: -40px;
    .imgConheca{
        img{
            width: 80%;
            margin: auto;
            display: block;
        }
    }
    &::before{
        content: "";
        height: 560px;
        width: 50%;
        right: 0;
        top: 0;
        float: left;
        position: absolute;
        background: linear-gradient(180deg, #CDCDCD 17.71%, #ECECEC 96.77%);
        z-index: -1;
    }
    .textConheca{
        padding-left: 5%;
        i{
            background: url(../../Home/Destaques/img/IconDestaque.svg) no-repeat center;
            width: 36px;
            height: 29px;
            display: inline-block;
          }
        h2{
            color: #272727;
            font-size: 26px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            width: 100%;
        }
        p{
            color: #8F8A8A;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.78px;
            margin-bottom: 40px;
        }
        a{
            border-radius: 39px;
            border: 1px solid #000000 !important;
            color: #000000 !important;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            padding: 6px 10px  6px 20px;
            svg{
                transform: rotate(45deg);
                font-size: 26px;
                color: #B8A558;
            }
        }
    }
}

@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1880px) {
    .Conheca {
        background-position: left -383px top;
        .textConheca {
            h2{
                font-size: 24px;
            }
        }
    }
}
@media (max-width: 768px) {
    .Conheca{
        background: #2CBE48 url(./img/bg.webp) no-repeat center bottom;
        padding-top: 60px;
        padding-bottom: 0;
        .textConheca {
            text-align: center;
            padding: 0 20px 40px;
            h2 {
                font-size: 20px;
                width: 100%;
            }
            h3, p{
                color: var(--whiteColor);
            }
            p{
                margin-top: 20px;
            }
        }
        .imgConheca img {
            margin-top: -20px;
            margin-bottom: 20px;
        }
        .row{
            > div{
                &:nth-child(1){
                    order: 2;
                }
                &:nth-child(2){
                    order: 1;
                }
            }
        }
    }
}



