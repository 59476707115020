// BannerVendaSeuCarro

.BannerVendaSeuCarro{
    background: url(./img/bg-BannerVendaSeuCarro.png) no-repeat center / cover;
    height: 460px;
    .boxBannerVendaSeuCarro{
        min-height: 400px;
        display: table;
        position: relative;
        .textBannerVendaSeuCarro{
            display: table-cell;
            vertical-align: middle;
            min-height: 400px;
            position: relative;
            text-align: center;
            h3{
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: var(--whiteColor);
                font-weight: 900;
                text-transform: uppercase;
                font-size: 38px;
                color: transparent;
                line-height: 40px;
                margin-top: 150px;
            }
            h2{
                font-size: 50px;
                font-weight: 900;
                color: var(--whiteColor);
                text-transform: uppercase;
                line-height: 40px;
            }
            p{
                color: #F3F3F3;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 1.08px;
            }
        }
    }


}


@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1280px) {

}
@media (max-width: 768px) {

}
