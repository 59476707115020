// Destaques

.Destaques{
    position: relative;
    padding: 20px 0 100px;
    &::after{
        content: "";
        width: 100%;
        height: 20px;
        position: absolute;
        left: 0;
        top: -20px;
        z-index: 0;
        background: #EEEEF2;
    }
    .sliderDestaques{
        padding: 0;
        width: 100% !important;
    }
    .SliderControl{
        position: relative;
        
        a.nextSlider {
            border: 1px solid #D0D0D0;
            width: 52px;
            height: 52px;
            z-index: 1;
            background: #FFF;
            position: absolute;
            right: 10px;
            bottom: -50px;
            color: #D0D0D0;
            text-align: center;
            line-height: 48px;
            transition: all ease-in 0.2s;
            font-size: 12px;
            &:hover{
                background: #F5F5F5;
                cursor: pointer;
            }
        }
        a.prevSlider {
            border: 1px solid #D0D0D0;
            width: 52px;
            height: 52px;
            z-index: 1;
            background: #FFF;
            position: absolute;
            right: 61px;
            bottom: -50px;
            color: #D0D0D0;
            text-align: center;
            line-height: 48px;
            transition: all ease-in 0.2s;
            font-size: 12px;
            &:hover{
                background: #F5F5F5;
                cursor: pointer;
            }
        }
    }
    .slick-arrow{
        display: none !important;
    }
    .slick-slider{
        .slick-slide{
            padding: 10px;
        }
    }
    .itemVeiculos{
        height: auto;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        background: var(--whiteColor);
        margin-bottom: 20px;
        transition:  all ease-in 0.2s;
        .foto{
            width: 100%;
            height: 260px;
            display: inline-block;
            background: #000;
            transition: all ease-in 0.2s;
            position: relative;
            background-size: cover !important;
            span{
                background: rgb(48,189,68);
                background: linear-gradient(90deg, #D9D9D9 0%, #6C6C6C 100%);
                color: var(--whiteColor);
                font-weight: 600;
                top: 0;
                left: 0;
                border-radius: 8px 0 0 0;
                position: absolute;
                padding: 4px 20px;
                text-transform: uppercase;
                font-size: 13px;
                letter-spacing: 1px;
            }
            .baixo{
                background: linear-gradient(90deg, #6C6C6C 50%, #d9d9d970  100%);
            }
            /*&::before{
                content: "";
                background: linear-gradient(180deg, rgba(4, 4, 4, 0.00) 0%, rgba(4, 4, 4, 0.37) 50.71%, rgba(4, 4, 4, 0.70) 100%);
                height: 50%;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
            }*/
        }
        .desc{
            position: relative;
            width: 100%;
            transition: all ease-in 0.2s;
            height: 90px;
            .btn-plus{
                border-radius: 200px !important;
                width: 46px;
                height: 46px;
                text-align: center;
                color: #B8A558;
                padding: 0 !important;
                line-height: 42px;
                transition: all ease-in 0.2s;
                svg{
                    transform: rotate(45deg);
                    font-size: 40px;
                    color: #B8A558;
                }
            }
            .modelo{
                background: none;
                padding: 20px 30px 10px 30px;
                height: 120px;
                h3{
                    color: var(--primaryColor);
                    font-size: 18px;
                    line-height: 20px;
                    font-weight: bold;
                    text-transform: uppercase;
                    width: calc(100% - 50px);
                    span{
                        color: var(--tertiaryColor);
                        font-size: 12px;
                        text-transform: uppercase;
                        display: inline-block;
                        width: 100%;
                    }
                }
                .marca{
                    color: #969696;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14px;
                    text-transform: uppercase;
                    margin-bottom: 2px;
                }
            }
            .galeria{
                position: relative;
                top: 0;
                right: 0;
                width: 100%;
                ul{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    width: 100%;
                    gap: 8px;
                    li{
                        overflow: hidden;
                        margin: 0;
                        padding: 0;
                        display: inline-block;
                        width: auto;
                        width: 33%;
                        height: 65px;
                        background: var(--primaryColor);
                        border-radius: 0;
                        filter: grayscale(100%);
                        transition: all ease-in 0.2s;
                        &:hover{
                            filter: grayscale(0%); 
                        }
                    }
                }
            }
            .valor{
                h4{
                    background: #272727;
                    border-radius: 0 12px 0 0;
                    width: 70%;
                    display: inline-block;
                    color: var(--whiteColor);
                    text-align: center;
                    height: 60px;
                    line-height: 60px;
                    font-size: 18px;
                }
            }
            .acao{
                width: 30%;
                display: inline-block;
                text-align: center;
                height: 60px;
                float: right;
            }
        }
        &:hover{
            cursor: pointer;
            .desc{
            
                .modelo{
                    h3{
                       
                    }
                }
                
            }
            .btn-plus{
                background: var(--tertiaryColor);
                border: 1px solid var(--tertiaryColor) !important;
                svg{
                    color: var(--whiteColor);
                }
            }
            .foto{
                background: var(--whiteColor);
            }
        }
    }
    .headerAction{
        text-align: center;
        padding-top: 60px;
            .btn-quaternary{
                svg{
                    transform: rotate(45deg);
                    font-size: 26px;
                    color: #B8A558;
                }
            }
        }
    &.blindados{
        background: rgb(36,36,36);
        background: linear-gradient(90deg, rgba(36,36,36,1) 0%, rgba(47,47,47,1) 100%);
        &::before{
            content: "blindados";
            top: 300px !important;
            background: linear-gradient(90deg, rgba(217,217,217,0.20) 0%, rgba(217,217,217,0.05) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .titilos{
            span{
               color: var(--whiteColor); 
            }
        }
        .headerAction{
            .btn-quaternary{
                svg{
                    transform: rotate(45deg);
                    font-size: 26px;
                    color: #B8A558;
                }
            }
        }
        .SliderControl{
            position: relative;
            
            a.nextSlider {
                border: 1px solid #D0D0D0;
                background: #35363A;
                &:hover{
                    background: #F5F5F5;
                    cursor: pointer;
                }
            }
            a.prevSlider {
                border: 1px solid #D0D0D0;
                background: #35363A;
                &:hover{
                    background: #F5F5F5;
                    cursor: pointer;
                }
            }
        }
    }
    .titilos{
        position: relative;
        z-index: 1;
        span{
            background: #EEEEF2;
            padding-right: 20px;
        }
        &:after{
            content: "";
            border-bottom: 1px solid #C1C1C1;
            width: 100%;
            height: 1px;
            top: 80px;
            position: absolute;
            z-index: -1;

        }
    }
}



@media(min-width: 1621px) and (max-width: 1800px) {

}

@media(min-width: 1024px) and (max-width: 1620px) {
 

}

@media(min-width: 1024px) and (max-width: 1380px) {
    .Destaques {
        .sliderDestaques {
            padding: 0;
        }
        &::before {
            right: -190px;
        }
    }
}
@media (max-width: 768px) {
    .Destaques{
        padding: 20px 0 40px;
        overflow: hidden;
        &.blindados{
            background: rgba(36, 36, 36, 1);
        }
        &::before {
            display: none;
        }
        .headerAction {
            text-align: center;
            padding-top: 30px;
        }
        .sliderDestaques {
            padding: 20px 60px 0 10px;
            width: 85%;
            .slick-list{
                overflow: inherit;
                
            }
        }
        .SliderControl {
            display: none;
        }
        .itemVeiculos {
            .desc {
                .modelo {
                    padding: 10px 20px;
                    margin-top: 20px;
                    h3 {
                        font-size: 16px;
                    }
                }

                .valor{
                    h4{
                        font-size: 14px;
                    }
                }
            }
            .foto {
                height: 300px;
                background-size: cover !important;
            }
        }
    }
}