// Galeria
.galeriaContainer{
    margin-top: 0px;
    .btn.btn-tertiary{
        display: none;
    }
    .galeria{
        margin-bottom: 120px;
        max-height: 600px;
        overflow: hidden;
        overflow-y: auto;
        .row{
            padding: 0;
            margin: 0;
            > div{
                padding: 0;
                margin: 0;
            }
            
        }
        
    }
    .slick-list{
        height: 500px;
    }
    .photo{
        width: 100%;
        height: 500px;
        display: inline-block;
        position: relative;
        float: left;
        border: 4px solid rgba(238, 238, 238, 0.87);
        overflow: hidden;
        cursor: pointer;
        &:hover{
            opacity: 0.9;
        }
    }
    .slick-prev, .slick-next{
        right: 60px;
        bottom: 40px;
        top: inherit;
        left: inherit;
        z-index: 9;
        width: 50px;
        height: 50px;
        opacity: 0.8;
        &::before{
            display: none;
        }
    }
    .slick-next{
        background: url(./img/next.png) no-repeat;
        &:hover{
            background: url(./img/next.png) no-repeat;
            opacity: 1;  
            z-index: 9;
        }
    }
    .slick-prev{
        bottom: 100px;
        background: url(./img/prev.png) no-repeat;
        &:hover{
            background: url(./img/prev.png) no-repeat;
            z-index: 9;
        }
    }
}


.galeria::-webkit-scrollbar {
    width: 10px; 
}


.galeria::-webkit-scrollbar-thumb {
    background-color: var(--tertiaryColor); 
    border-radius: 6px; 
}


.galeria::-webkit-scrollbar-thumb:hover {
    background-color: var(--tertiaryColor); 
}

@media (max-width: 768px) {
    .galeriaContainer{
        padding-bottom: 40px;
        .galeria {
            max-height: 400px;
            margin-bottom: 0;
            .photo {
                height: 134px;
            }
        }
        .slick-list {
            height: 310px;
        }
        .btn {
            margin-bottom: 60px !important;
            width: 100%;
            margin-top: 10px;
        }
        .slick-prev {
            bottom: -25px;
            right: 85px;
        }
        .slick-next{
            right: 30px;
            bottom: -24px;
            opacity: 1;
        }
    }
}